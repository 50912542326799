import HomeScrollPicFragment from "../../components/dashboard/HomeScrollPicFragment";
import HomeScrollQuizFragment from "../../components/dashboard/HomeScrollQuizFragment";
import HomeTextFragment from "../../components/dashboard/HomeTextFragment";
import HomeScrollVidFragment from "../../components/dashboard/HomeScrollVidFragment";
import PrimaryButton from "../../components/PrimaryButton";
import { useEffect, useRef, useState } from "react";
import UserFeedard from "../../components/dashboard/UserFeedCard";
import { useNavigate } from "react-router-dom";
import { AppPaths } from "../../utils/AppPaths";
import EmojiModal from "../../components/dashboard/EmojiModal";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import DiamondIcon from "../../assets/diamond.png";
import unicornImg from "../../assets/unicorn.svg";
import CrackerImg from "../../assets/Crackers.png";
import WelcomeImg from "../../assets/welcome.gif";
import CompletedImg from "../../assets/completed_challenge.png";

import {
  setActiveTab,
  setEmojiModal,
  setPointData,
  setPointsTracker,
  setUserData,
} from "../../redux/rootSlice";
import PointsTrackerModal from "../../components/PointsTrackerModal";
import { setSelctedImoji } from "../../redux/homeScreenSlice";
import LoadImage from "../../assets/loader.gif";
import {
  AnxiousImages,
  InBetweenImage,
  difficultImage,
  getColor,
  getImageType,
  getImageUrl,
  happyImages,
} from "../../constants/reactionImages";
import ApiService from "../../api/ApiServices";
import { Modal } from "../../components/Unicorn";
import moment from "moment";
import toast from "react-hot-toast";
import { convertTo24Hour } from "../../utils/utils";
import { isMobile } from "react-device-detect";


const HomeScreen = () => {
  const [bedTime, setBedTime] = useState("");
  const [wokeUpTime, setWokeUpTime] = useState("");
  const [submit, setSubmit] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [responseData, setResponseData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [challengeLoad, setChallengeLoad] = useState<boolean>(false);
  const [alreadyCheckIn, setAlreadyCheckIn] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [feeds, setFeeds] = useState<any>();
  const [checkInPoints, setCheckInPoints] = useState<any>();
  const [housePoints, setHousePoints] = useState<any>();
  const { emojiModal } = useAppSelector((state) => state.root);
  const emojiRef = useRef<HTMLDivElement | null>(null);
  const { selectedImoji, recentUsedImoji } = useAppSelector(
    (state) => state.home
  );
  const [info, setInfo] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);

  const [readMoreModal, setReadMoreModal] = useState(false);
  const handleReadMore = (data: any) => {
    setInfo(data);
    setReadMoreModal(!readMoreModal);
  };
  const handleClose = () => {
    setIsOpen(false);
    navigate(AppPaths.HOME);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveTab(2));
  }, [dispatch]);
  useEffect(() => {
    dispatch(setSelctedImoji(Object.entries(AnxiousImages)[0]));
    dispatch(setSelctedImoji(Object.entries(happyImages)[0]));
    dispatch(setSelctedImoji(Object.entries(InBetweenImage)[1]));
  }, []);

  const navigate = useNavigate();

  const handleBedTimeChange = (e: any) => {
    setBedTime(e.target.value);
  };
  const handleWokeUpChange = (e: any) => {
    setWokeUpTime(e.target.value);
  };

  const handleSubmit = async () => {
    if (wokeUpTime !== bedTime) {
      const response = await ApiService.dailyCheckIn({
        bedTime: bedTime,
        wakeupTime: wokeUpTime,
        todayFeeling: selectedImoji?.[0],
        date: moment().format("YYYY-MM-DD"),
        emojiType: selectedImoji
          ? getImageType(selectedImoji[0].toString())
          : null,
      }).then((data) => {
        if (data?.status) {
          setResponseData(data?.data);
          setCheckInPoints(checkInPoints + 5);
        }
      });
    } else {
      toast.error("Please check bed time and woke up time");
    }
  };

  const editSubmit = async () => {
    if (wokeUpTime !== bedTime) {
      const response = await ApiService.editDailyCheckIn({
        bedTime: bedTime,
        wakeupTime: wokeUpTime,
        todayFeeling: selectedImoji?.[0],
        date: moment().format("YYYY-MM-DD"),
        emojiType: selectedImoji
          ? getImageType(selectedImoji[0].toString())
          : null,
      }).then((data) => {
        if (data?.status) {
          setResponseData(data?.data);
          setEdit(true);
        }
      });
    } else {
      toast.error("Please check bed time and woke up time");
    }
  };

  useEffect(() => {
    if (responseData) {
      setSubmit(true);
    }
  }, [responseData]);

  useEffect(() => {
    if (emojiRef.current) {
      emojiRef.current.scrollLeft = 0;
    }
  }, [selectedImoji]);

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.user?.userCheckin) {
        setSubmit(true);
        setResponseData(userDetails?.user?.usercheckInData);
        const bed = convertTo24Hour(
          userDetails?.user?.usercheckInData?.bedTime
        );
        const wake = convertTo24Hour(
          userDetails?.user?.usercheckInData?.wakeupTime
        );
        setBedTime(bed);
        setWokeUpTime(wake);
        setAlreadyCheckIn(true);
      }
    }
  }, [userDetails]);

  const getFeedData = async () => {
    setLoad(true);
    const response = await ApiService.getProfile();
    setUserDetails(response?.data);
    setHousePoints(response?.data?.challengePoints);
    setFeeds(response?.data?.feeds);
    setCheckInPoints(response?.data?.checkInPoints);
    dispatch(setUserData(response?.data?.user));
    dispatch(
      setPointData({
        challengePoints: response?.data?.challengePoints,
        checkInPoints: response?.data?.checkInPoints,
      })
    );
    localStorage.setItem("userId", response?.data?.user?._id);
    setLoad(false);
  };

  useEffect(() => {
    if (userDetails === null) {
      getFeedData();
    }
  }, []);

  const renderTopGradient = () => (
    <svg
      // width="375"
      // height="123"
      viewBox="0 0 365 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: -32,
        zIndex: -10,
      }}
    >
      <path
        d="M-66 43L44.741 18.2962C142.95 -3.6119 244.864 -2.80936 342.716 20.6427L436 43"
        stroke="url(#paint0_linear_1102_5554)"
        stroke-width="133"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1102_5554"
          x1="16"
          y1="43"
          x2="363"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9EEB" />
          <stop offset="0.145833" stop-color="#FF9728" stop-opacity="0.74" />
          <stop offset="0.302083" stop-color="#FFD203" stop-opacity="0.61" />
          <stop offset="0.510417" stop-color="#F1F232" stop-opacity="0.48" />
          <stop offset="0.65625" stop-color="#06AE56" stop-opacity="0.67" />
          <stop offset="0.859375" stop-color="#22A094" stop-opacity="0.77" />
          <stop offset="0.994236" stop-color="#91A8EC" />
        </linearGradient>
      </defs>
    </svg>
  );
  const renderHeaderImages = (data: any) => {
    return (
      <div className="z-10 flex pt-1 mb-4">
        <div
          onClick={() => {
            dispatch(setPointsTracker(true));
          }}
          className="flex flex-row items-center flex-1 pl-5"
        >
          <img
            src={
              data?.house?.image
                ? data?.house?.image
                : require("../../assets/placeholder_gem.png")
            }
            alt=""
            className="header-jem"
          />
          <div className="px-3 inter-text-16-extrabold">
            {housePoints}&nbsp;pts
          </div>
        </div>
        <img
          src={require("../../assets/u4uOnb.png")}
          alt=""
          className="header-u4u"
        />
        <div className="flex flex-row items-center justify-end flex-1 pr-5">
          <img
            src={require("../../assets/header_star.png")}
            alt=""
            className="mr-3 header-star "
          />
          <div className="inter-text-16-extrabold">
            {checkInPoints}&nbsp;pts
          </div>
        </div>
      </div>
    );
  };

  const renderCheckInSaved = () => {
    return (
      <>
        {((responseData && Object.keys(responseData).length > 0) ||
          alreadyCheckIn) && (
            <div
              className="flex flex-row flex-1 mx-3 home-frag-checkin"
              style={{ backgroundColor: "#FAEDF9" }}
            >
              <div className="flex flex-col items-center flex-1 py-4 dailt-check-box-filled ">
                <div className="sub-title-text-16">I’m feeling...</div>
                <img
                  alt=""
                  className="emoji-l"
                  src={getImageUrl(responseData?.todayFeeling)}
                />
                <div className="onb1-text">{responseData?.todayFeeling}</div>
              </div>
              <div className="flex flex-col items-center flex-1 py-4">
                <div className="sub-title-text-16">I slept...</div>
                <div className="flex flex-row items-baseline">
                  <div className="input-text-44-bold">
                    {responseData?.timeDiff?.hours}
                  </div>
                  <div className="inline-block input-text-12-bold">HRS</div>
                </div>
                <div className="inter-regular-12">
                  {responseData?.bedTime} - {responseData?.wakeupTime}
                </div>
              </div>
            </div>
          )}
      </>
    );
  };

  const renderCheckInCard = () => {
    return (
      <div className="py-3 mx-3 home-frag-checkin">
        <div className="mx-3 inter-18-bold">I’m feeling...</div>

        <div className="flex flex-row py-2 ml-3 overflow-x-auto" ref={emojiRef}>
          <div className="grid grid-flow-col grid-rows-1 gap-1">
            {recentUsedImoji
              ?.slice()
              ?.reverse()
              ?.map((img: any) => (
                <div
                  onClick={() => {
                    dispatch(setSelctedImoji(img));
                  }}
                  className={`emoji-cont emoji-cont-width flex flex-col items-center ${selectedImoji?.[0] === img?.[0] && "emoji-selected"
                    } `}
                  style={{
                    backgroundColor: selectedImoji
                      ? getColor(img?.[0])
                      : undefined,
                  }}
                >
                  <img src={img?.[1]} alt="" className="emoji" />
                  <div
                    className="flex flex-row flex-wrap items-center pt-2 text-center inter-text-9-semibold"
                  // style={{ whiteSpace: "pre-wrap", width: 50 }}
                  >
                    {img?.[0]}
                  </div>
                </div>
              ))}
            <div
              onClick={() => {
                dispatch(setEmojiModal(true));
                // navigate(AppPaths.EMOJI_MODAL);
              }}
              className="flex flex-col items-center emoji-cont emoji-cont-width"
            >
              <div className="flex items-center flex-1 ">
                <img
                  src={require("../../assets/plus.png")}
                  alt=""
                  className="my-2 add"
                />
              </div>
              <div className="flex flex-row flex-wrap items-center text-center inter-text-9-semibold ">
                See More
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-1 mx-3 my-3 ">
          <div className="flex flex-row items-center justify-between flex-1 mr-4 ">
            <div className="flex flex-1 mr-2 text-center inter-regular-14">
              I went to bed at...
            </div>
            {false ? (
              <img
                alt=""
                src={require("../../assets/moon_empty.png")}
                className="daily-check-time-icon-2"
              />
            ) : (
              <img
                alt=""
                src={require("../../assets/sleep_filled.png")}
                className="daily-check-time-icon"
              />
            )}
          </div>
          <div className="flex flex-row items-center justify-between flex-1">
            <div className="mr-2 text-center inter-regular-14 ">
              I woke up at...
            </div>
            {false ? (
              <img
                alt=""
                src={require("../../assets/sun_empty.png")}
                className="daily-check-time-icon"
              />
            ) : (
              <img
                alt=""
                src={require("../../assets/sun-filles.png")}
                className="daily-check-time-icon"
              />
            )}
          </div>
        </div>
        <div className="flex flex-row flex-1 mx-3 mb-3">
          {/* <TextInput
            placeholder="--:--"
            value={bedTime}
            textStyle={"text-center"}
            onChange={handleBedTimeChange}
            containerStyles="px-3 py-2 mr-3"
            textInputStyles={{ maxWidth: "100%" }}
          /> */}
          <div className="flex flex-row flex-1 px-3 py-2 TextInput relative mr-3">
            <input
              type="time"
              className={`w-full flex justify-center text-center outline-none inter-regular-16 ${!bedTime ? "font-white" : "font-black"
                }`}
              style={{
                backgroundColor: "#ffffff",
              }}
              value={bedTime}
              onChange={handleBedTimeChange}
              min="09:00"
              max="12:00"
            />
            {!bedTime ? (
              <span
                style={{
                  display: "flex",
                  pointerEvents: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontFamily: "Inter-Regular",
                  fontSize: "24px",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                --:--
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  pointerEvents: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  backgroundColor: "white",
                  fontFamily: "Inter-Bold",
                  fontSize: "17px",
                  color: "black",
                }}
              >
                {moment(bedTime, "HH:mm").format("h:mm A")}
              </span>
            )}
          </div>

          <div className="flex flex-row flex-1 px-3 py-2 TextInput relative mr-3">
            <input
              type="time"
              className={`w-full flex justify-center text-center outline-none inter-regular-16 ${!wokeUpTime ? "font-white" : "font-black"
                }`}
              style={{
                backgroundColor: "#ffffff",
              }}
              value={wokeUpTime}
              onChange={handleWokeUpChange}
              min="09:00"
              max="12:00"
            />
            {!wokeUpTime ? (
              <span
                style={{
                  display: "flex",
                  pointerEvents: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontFamily: "Inter-Regular",
                  fontSize: "24px",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                --:--
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  pointerEvents: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  backgroundColor: "white",
                  fontFamily: "Inter-Bold",
                  fontSize: "17px",
                  color: "black",
                }}
              >
                {moment(wokeUpTime, "HH:mm").format("h:mm A")}
              </span>
            )}
          </div>
        </div>
        <PrimaryButton
          text="SUBMIT"
          onClick={() => {
            // setSubmit(true);
            if (userDetails?.user?.userCheckin) {
              editSubmit();
            } else {
              handleSubmit();
            }
          }}
          containerStyles="px-1 py-1 mx-3 "
          textStyle="inter-12-bold"
        />
      </div>
    );
  };

  const getChallengeList = async () => {
    setChallengeLoad(true);
    const response = await ApiService.getChallenges();
    setUserDetails({ ...userDetails, challenges: response?.data });
    setTimeout(() => {
      setChallengeLoad(false);
    }, 3000);
  };

  const getFeeds = async () => {
    const response = await ApiService.getFeeds();
    setFeeds(response?.data)
  }
  const handleSuccess = (response: any) => {
    getChallengeList();
    setHousePoints(housePoints + response?.data?.points);
    setCheckInPoints(checkInPoints + response?.data?.points);
    getFeeds();
    setFeeds([...response?.data, feeds]);
  };

  const renderChallenges = (challenge: any) => {
    if (challenge?.type === "text") {
      return (
        <HomeTextFragment
          id={challenge?._id}
          picUrl={challenge?.url}
          handleSuccess={handleSuccess}
          handleReadMore={handleReadMore}
          data={challenge}
        />
      );
    } else if (challenge?.type === "video") {
      return (
        <HomeScrollVidFragment
          id={challenge?._id}
          picUrl={challenge?.url}
          handleSuccess={handleSuccess}
          handleReadMore={handleReadMore}
          data={challenge}
          thumbnail={challenge?.metadata?.thumbnail}
        />
      );
    } else if (challenge?.type === "image") {
      return (
        <HomeScrollPicFragment
          id={challenge?._id}
          picUrl={challenge?.url}
          handleSuccess={handleSuccess}
          handleReadMore={handleReadMore}
          data={challenge}
        />
      );
    }
    return (
      <HomeScrollQuizFragment
        id={challenge?._id}
        picUrl={challenge?.metadata?.thumbnail}
        handleReadMore={handleReadMore}
        data={challenge}
      />
    );
  };

  return (
    <>
      {load ? (
        <div className="flex items-center justify-center h-screen mb-5">
          <img width="100px" className="center" src={LoadImage} alt="loader" />
        </div>
      ) : (
        <div className="relative flex flex-col pb-20">
          {/* <div className="absolute top-0 left-0 right-0 gradient-container "></div> */}
          <div className="absolute">
            <EmojiModal
              setVisible={(val) => {
                dispatch(setEmojiModal(val));
              }}
              visible={emojiModal}
            />
            {userDetails?.houseMatches.length > 0 && (
              <PointsTrackerModal data={userDetails?.houseMatches} />
            )}
          </div>
          {renderTopGradient()}
          {renderHeaderImages(userDetails)}
          <div className="text-center inter-18-bold">
            Welcome home, {userDetails?.user?.name}!
          </div>
          {userDetails?.challenges?.length === 0 && userDetails?.user?.houseId === null && (
            <div className="m-2 border-[3px] border-black rounded-lg">
              <img src={WelcomeImg} style={{ borderRadius: '0.5rem' }} alt="welcome image" />
            </div>
          )}
          {userDetails?.challenges?.length === 0 && userDetails?.user?.houseId !== null && (
            <div className="m-2 border-[3px] border-black rounded-lg">
              <img src={CompletedImg} style={{ borderRadius: '0.5rem' }} alt="completed challenge image" />
            </div>
          )}
          {challengeLoad ? (
            <div className="flex py-2 pl-3 mt-1 overflow-x-auto justify-center">
              <div
                className="flex justify-center py-4 bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${CrackerImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "51% 22%",
                  backgroundSize: "76%",
                }}
              >
                <img
                  src={unicornImg}
                  alt="Your Image"
                  className="border-2 border-black rounded-lg w-50 h-60"
                />
              </div>
            </div>
          ) : (
            <div
              className="flex py-2 pl-3 mt-1 overflow-x-auto mx-auto"
              style={{ maxWidth: "46vh" }}
            >
              {userDetails?.challenges?.map((item: any) => {
                return renderChallenges(item);
              })}
            </div>
          )}
          <div className="flex flex-row items-center px-3 pt-4 mb-2">
            <div className="mr-2 text-center inter-18-bold">Daily Check-in</div>
            <div className="flex flex-row items-center text-center inter-text-14-semibold rating">
              <img
                src={require("../../assets/header_star.png")}
                alt=""
                className="w-5 h-5 mr-1"
              />
              5 pts
            </div>
            {submit && (
              <div
                className="flex justify-end flex-1"
                onClick={() => {
                  setSubmit(false);
                }}
              >
                <img
                  src={require("../../assets/edit.png")}
                  alt=""
                  className="w-5 h-5 mr-1"
                />
              </div>
            )}
          </div>
          {!submit ? renderCheckInCard() : renderCheckInSaved()}
          {/* {userDetails} */}
          {userDetails?.house && (
            <div className="flex flex-1 flex-row items-center">
              <div className="inter-20-bold ml-3 mt-5">
                {userDetails?.house?.name} House Feed{" "}
              </div>
              <span className="flex flex-row items-center mx-2 mt-4">
                <img
                  src={userDetails?.house?.image}
                  height="25"
                  width="25"
                  style={{ paddingTop: "3px" }}
                  alt="diamond"
                />
              </span>
            </div>
          )}
          {feeds?.map((item: { challengeId: { type: any; data: any } }) => {
            return <UserFeedard data={item} type={item?.challengeId?.type} />;
          })}
          {/* <UserFeedard type="img" /> */}
          {/* <UserFeedard type="text" /> */}
          {isOpen && <Modal isOpen={isOpen} onClose={handleClose} />}
        </div>
      )}
      {/* {readMoreModal ? (
        <div className="fixed max-w-80 min-h-auto bg-[#D9D9D9] p-4 flex justify-center items-center h-screen overflow-auto z-10">
          <div className="h-40 min-h-[480px]">
            <button
              className="absolute top-2 right-2 text-lg font-medium bg-white w-8 h-8 rounded-full"
              onClick={() => setReadMoreModal(false)}
            >
              x
            </button>
            <p className="text-sm font-medium text-black">More info: {info}</p>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {readMoreModal ? (
        <div className="fixed !max-w-[430px] w-full min-h-auto flex items-center justify-center !bg-[#000000c4] !p-0 shadow-none !h-screen z-10 before:!hidden">
        <div className="fixed max-w-80 min-h-auto bg-[#D9D9D9] p-4 overflow-auto z-10 !h-40 min-h-[480px]">
            <button
              className="absolute top-2 right-2 text-lg font-medium bg-white w-8 h-8 rounded-full"
              onClick={() => setReadMoreModal(false)}
            >
              x
            </button>
            <p className="text-sm font-medium text-black">{info}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default HomeScreen;
