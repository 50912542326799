import { ChangeEvent, useState } from "react";
import { baseURL } from "../../api/Api";
import "../../container/styles.css";
import toast from "react-hot-toast";
import ApiService from "../../api/ApiServices";
import { Modal } from "../Unicorn";

const HomeScrollPicFragment = ({
  onClick,
  hideOptions = false,
  imgStyle = null,
  contStyle = null,
  id,
  picUrl,
  handleSuccess,
  handleReadMore,
  data,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  hideOptions?: boolean;
  imgStyle?: any;
  contStyle?: any;
  id?: any,
  picUrl?: any,
  handleSuccess?: any,
  handleReadMore?: any;
  data?: any,
}) => {
  const updatePicQuiz = async (profile: string) => {
    try {
      const response = await ApiService.answerText({
        challengeId: id,
        answer: profile,
      });
      if (response?.status) {
        toast.success(response?.message)
        handleSuccess(response)
      }
    } catch (error) {
      console.error("Error while updating user profile", error);
    }
  }
  function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);

    fetch(`${baseURL}/upload`, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to upload file');
        }
        return response.json();
      })
      .then(data => {
        updatePicQuiz(data?.data)
      })
      .catch(error => {
        toast.error('Error uploading file:', error);
      });
  }
  return (
    <div
      onClick={onClick}
      className={`flex flex-col flex-1 home-frag-cont relative ${!hideOptions && `mr-3`
        }
      ${contStyle && contStyle}
      `}
    >
      {data?.additionalInfo !== "" && <button
        className="absolute top-3 right-4 text-sm font-bold h-8 w-8 text-black readmore-btn"
        onClick={() => handleReadMore(data?.additionalInfo)}
      >
        ?
      </button>}
      <img
        alt=""
        className={`home-frag-img ${imgStyle}`}
        src={picUrl || require("../../assets/homeFragPic.png")}
      />
      {!hideOptions && (
        <div className="absolute left-0 right-0 bottom-4">
          <div className="flex flex-row flex-1 justify-center ">
            <label htmlFor="file-upload" className="secondary-btn px-4 py-4 cursor-pointer">
              <img
                src={require("../../assets/camera.png")}
                className="home-frag-pic-img"
                alt=""
              />
            </label>
            <input accept=".png, .jpg, .jpeg" id="file-upload" type="file" className="hidden" onChange={handleFileUpload} />
            {/* <div className=" secondary-btn px-4 py-4  cursor-pointer">
              <img
                src={require("../../assets/gallery.png")}
                className="home-frag-pic-img"
                alt=""
              />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeScrollPicFragment;
